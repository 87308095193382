import React, { useEffect, useState } from "react";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import "./AuthPage.scss";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "../../aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import HeaderBar from "../../components/HeaderBar/HeaderBar";
import FooterBar from "../../components/FooterBar/FooterBar";

Amplify.configure(awsExports);

export default function AuthPage() {
  /**
   * Check the language version from HeaderBar componentand update the content to that language
   * EN - English, CN - Chinese
   */
  const [pageLanguage, setPageLanguage] = useState(() => {
    return localStorage.getItem("preferredLanguage") || "EN";
  });
  const updatePageLanguageVariable = (languageValue) => {
    setPageLanguage(languageValue);
  };
  const navigate = useNavigate();
  // Retrieve the last visited URL from local storage
  const lastVisitedPage =
    localStorage.getItem("lastVisitedPage") == null
      ? sessionStorage.getItem("lastVisitedPage")
      : localStorage.getItem("lastVisitedPage");
  console.log("AuthPage.js | lastVisitedPage = ", lastVisitedPage);

  return (
    <article className="AuthContainer">
      <section className="Header">
        <HeaderBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          headerLanguage={pageLanguage}
        />
      </section>
      <div className="AuthBody">
        <Authenticator signUpAttributes={["email", "nickname"]}>
          {() => navigate(lastVisitedPage)}
        </Authenticator>
      </div>
      <section className="Footer">
        <FooterBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          footerLanguage={pageLanguage}
        />
      </section>
    </article>
  );
}
